import React from "react";

import './Presidesk.css';

import president from './assets/brand/president.png';


const Presidesk = () => {

    return (
        <div className="container President-parent mb-5 mt-5">
            <h1 className="text-custom-blue">From President's Desk</h1>
            <h3 className="text-danger">"Courage and simplicity is the power to win"</h3>


            <div className="row mt-5">
                <div className="col-12 col-md-6 pres-col">
                    <img src={president} className="img-fluid presi-img"  alt="" />
                </div>
                <div className="col-12 col-md-6 ">
                    <h2 className="text-custom-blue"> Shri. Mangesh B. Harne  </h2>
                    <h3 className="text-danger">Hon'ble President</h3>
                    <h4>Jai Shree Siddhivinayak Foundation Dombivali (West)</h4>
                    <p className="fs-p">
                    Jai Shree Siddhivinayak Foundation was established in the year 2007 with a social commitment to providing professional, competitive, and industry- relevant education to the younger generation of rural and urban areas. Globalization and liberalization have changed all the concerns and references. There is a need to reconsider the present education at all its levels and hence it is necessary to deviate from the traditional methods and use new methods and technology for imparting education.
In today’s era of globalization, an organization needs qualified technocrats and managers filled with enthusiasm and a spirit of hard work blended with self-confidence to achieve their goals. At Jai Shree Siddhivinayak Foundation’s B. R. Harne College of Engineering and Technology, we have put together the organizational learning and insights gathered over time and combined them with innovative, state-of-the-art delivery mechanisms that will equip the younger generation to take on the challenges both in India and overseas.
The institute has a very special place in my heart. Since 2022, BRHCET has developed excellent infra-structural facilities with well-equipped laboratories and an enriched library, which will be updated regularly. Similarly, I believe that in the near future, the institute will produce competent technocrats and managers who will make a significant contribution to the corporate world and industries all over the world. All the Best & Welcome!
                    </p>

                </div>
            </div>

        </div>
    );
};




export default Presidesk;