import React from "react";

const Contact = () => {
    return(
        <div className="container" style={{ marginTop: '40px', marginBottom: '90px' }}>
            <h1 className="text-custom-blue">Contact Us</h1>
            <div className="row mt-5">
                <div className="col-12 col-md-6">
                    <iframe 
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.73115169039!2d73.30988192592123!3d19.119446732094154!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7f25098936311%3A0xed2a0bb2061d8e9!2sB%20R%20Harne%20College%20Of%20Engineering!5e0!3m2!1sen!2sin!4v1732080123330!5m2!1sen!2sin" 
                        width="500" 
                        height="300" 
                        style={{ border: '0' }} 
                        allowFullScreen=""
                        loading="lazy"
                    ></iframe>
                </div>
                <div className="col-12 col-md-6">
                    <h3 className="text-custom-blue">Say Hello</h3>
                    <p>Email: brharnenursing@gmail.com
                        <br />
                        Phone: 0251-2485555 / 9665000861</p>

                    <h3 className="text-custom-blue">Find Us in</h3>
                    <p>Karav, Vangani(W), Tal. Ambernath, Dist. Thane</p>

                    <h3 className="text-custom-blue">Social Networks</h3>
                    <i className="fab fa-facebook fa-1x p-1"></i>
                    <i className="fab fa-instagram fa-1x p-1"></i>
                    <i className="fab fa-linkedin fa-1x p-1"></i>
                </div>
            </div>
        </div>
    );
};

export default Contact;
