import React from "react";


import './Footer.css';

import logo from './assets/brand/brnlogo.jpg';
import bizologo from './assets/brand/biz_logo.png';

const Footer = () => {
    return (
        <div className="container footer-parent">

            <div className="row mt-3">
                <div className="col-12 col-md-4 logo">
                    <img src={logo} alt="" className="img-fluid"/>

                </div>
                <div className="col-12 col-md-4 d-flex flex-column useful-links">
                    <h2 className="text-custom-blue">Useful Links</h2>
                    <a href="/CourseofInstruction">Courses</a>
                    <a href="/Eligibility">Eligibility Criteria</a>
                </div>

                <div className="col-12 col-md-4 d-flex flex-column contact-text">
                    <h2 className="text-custom-blue">Contact Details</h2>
                    <p><span>Address:</span><br /> Karav, Wangani, Tq.: Ambarnath, Dist.: Thane - 421503</p>
                    {/* <p>Website: www.brharnepharmacy.com</p> */}
                    <p>Phone : 9665000861 | 9975864482</p>
                    <p>Email : brharnenursing@gmail.com</p>
                    <p><span>Head Office:</span> <br />201, 2nd floor, Sunrise Galaxy, Ganashyam Gupte Road Vishnunagar, Near Railway Station, Dombivli(W)</p>

                </div>

            </div>

            <div className="footer-bottom mb-3">
                <div className="col-12 text-center">
                    <h3 className="text-custom-blue">Follow us on</h3>
                    <div className="social-icons d-flex justify-content-center mb-3">
                        <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="social-link">
                            <i className="fab fa-facebook fa-2x"></i>
                        </a>
                        {/* <a href="https://google.com" target="_blank" rel="noopener noreferrer" className="social-link">
                            <i className="fab fa-google fa-2x"></i>
                        </a>
                        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="social-link">
                            <i className="fab fa-instagram fa-2x"></i>
                        </a> */}
                    </div>
                    <p className="text-center">© Copyright 2023 B. R. Harne College Of Pharmacy. All Rights Reserved</p>
                    <p className="text-center">Designed and Managed by</p>
                    <a href="http://bizonance.in"><img src={bizologo} alt="" className="img-fluid" /></a>
                </div>
            </div>
                


        </div>
    );
};

export default Footer;