import React from "react";
import './About.css';

// import jsslogo from './assets/brand/jssf.png';
// import vision from './assets/stickers/vision.png';
// import mission from './assets/stickers/mission.png';




// import sposter1 from './assets/posters/brhpost1.jpeg';
// import sposter2 from './assets/posters/brhpost2.jpeg';
// import sposter3 from './assets/posters/brhpost3.jpeg';


// import lposter1 from './assets/posters/Addmition.png';
// import lposter2 from './assets/posters/addopen.jpg';
// import lposter3 from './assets/posters/rangoli.jpeg';


const About = () => {
    return (


        <div className="container about-parent">
            <div className="about-college">
                <h1 className="text-custom-blue">About College</h1>
                <p className="text-black">Jai Shri Siddhivinayak Foundation was established in 2007 by Hon'ble Sri. Mangeshji Harne, with the motive to propagate and spread education in the downtrodden, rural masses of the Western region of the state of Maharashtra. Education enables an individual to be independent, and self-sufficient financially besides raising productivity for Society. The Campus is spread over 33 Acres of land away from the hustle, and bustle of busy city traffic, in the peaceful area of Vangani near the banks of Ulhas river. A spacious, well- planned, well-lit, well-ventilated building surrounded by the lush green campus is many neighbors' envy. B. R. Harne School of Nursing is established in 2022 with approvals from MSBNPE, of Mumbai & Maharashtra Nursing Council. The college has well-equipped laboratories, spacious classrooms, a gym and games area, highly qualified faculties, medical emergency facility, transport facility, and all required facilities for the benefit of students.</p>
            </div>

            <div className="our-vision">
                <h1 className="text-custom-blue">Our Vision</h1>
                <p className="text-black">To prepare nurses who will work in the hospital and community with dedication, devotion, compassion and kindness. To develop a Centre for Excellence in nursing which will provide Quality educational programs which are evidence based and encourages innovations to improve patient care. Every student nurse who passes out from this institute will be an epitome of nursing with adequate scientific knowledge and will be able to work at primary, secondary and tertiary levels of care within the country and abroad. Develop a research cell where the ongoing researches will help to improve patient care and nursing education.</p>
            </div>

            <div className="our-mission">
                <h1 className="text-custom-blue">Our Mission</h1>
                <p className="text-black">The mission of College of Nursing is to provide Accessible, Affordable High Quality Education to diverse students for the development of nurse practitioners at all entries of practice. The mission will be accomplished through: Innovative Programs That Reflect The Needs And Current Trends In Health Care Of Neighbouring Communities And The Wider Geographic Regions. Provision Of The State Of The Art Health Care And Serve As A Resource For The Surrounding And Global Communities. Research That Advances The Scientific And Theoretical Foundations Of Nursing</p>
            </div>

        </div>
        // <div className="container about-parent">
        //     <h1 className="col-12 text-custom-green text-center aboutus mb-4">About Us</h1>
        //     <div className="row align-items-center">
        //         {/* Text Content */}
        //         <div className="col-12 col-md-6 text-content">
        //             <p>
        //             Jai Shri Siddhivinayak Foundation was established in 2007 by Hon'ble Sri. Mangeshji Harne, with the motive to propagate and spread education in the downtrodden, rural masses of the Western region of the state of Maharashtra. Education enables an individual to be independent, and self-sufficient financially besides raising productivity for Society. The Campus is spread over 33 Acres of land away from the hustle & bustle of busy city traffic, in the peaceful area of Vangani near the banks of Ulhas river. A spacious, well- planned, well-lit, well-ventilated building surrounded by the lush green campus is many neighbors' envy. B. R. Harne College of Pharmacy is established in 2022 with approvals from PCI, University of Mumbai, DTE, and MSBTE for the conduction of B.Pharm. and D. Pharm. Courses. The college has well-equipped laboratories, spacious classrooms, a gym and games area, highly qualified faculties, medical emergency facility, transport facility, and all required facilities for the benefit of students.
        //             </p>
        //         </div>
        //         {/* Image Content */}
        //         <div className="image-content col-12 col-md-6 text-center">
        //             <img src={jsslogo} alt="JSS Logo" className="img-fluid" />
        //         </div>
        //         <div className="col-12 col-md-6 vision text-custom-green d-flex flex-column justify-content-center">
        //             <h2>Our Vision</h2>
        //             <img src={vision} alt="" className="img-fluid"/>
        //             <p>"To foster our students’ enthusiasm towards developing successful careers".</p>
        //         </div>
        //         <div className="col-12 col-md-6 mission text-custom-green d-flex flex-column justify-content-center">
        //             <h2>Our Mission</h2>
        //             <img src={mission} alt="" className="img-fluid"/>
        //             <p>"Our mission is to build the foundation of well- rounded, confident, and responsible Pharmacists who aspire to achieve their full potential".</p>


        //         </div>
        //     </div>
        //     <div className="updates">
        //         <h1 className="text-custom-green col-12 text-center">Latest Updates</h1>
        //         <div className="row mt-4">

        //             <div className="col-12 col-md-4">
        //                 <img src={sposter1} alt="" className="img-fluid"/>
        //             </div>
        //             <div className="col-12 col-md-4">
        //                 <img src={sposter2} alt="" className="img-fluid"/>
        //             </div>
        //             <div className="col-12 col-md-4">
        //                 <img src={sposter3} alt="" className="img-fluid"/>
        //             </div>
        //             <div className="col-12 col-md-4">
        //                 <img src={lposter1} alt="" className="img-fluid mt-4"/>
        //             </div>
        //             <div className="col-12 col-md-4">
        //                 <img src={lposter2} alt="" className="img-fluid mt-4"/>
        //             </div>
        //             <div className="col-12 col-md-4">
        //                 <img src={lposter3} alt="" className="img-fluid mt-4"/>
        //             </div>

        //         </div>

        //     </div>
        // </div>
    );
};

export default About;
