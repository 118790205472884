import React from "react";
import './Philosophy.css';



const Philosphy = () => {

    return(

        <div className="container Philosphy-container mt-5 mb-5">
            <h2 className="text-custom-blue">Philosophy: </h2>
            <p>The faculty of the nursing institution believes that:</p>
            <ul>
              <li>The basic course in nursing is a formal educational preparation which should be based on sound educational principles as the foundation on which the practice of nursing is built, and on which further education depends.</li>    <br />
              <li>Nursing is a profession, which is influenced by advances in services and technology. So, nurses need to evaluate the curriculum periodically and make the required changes, both in theory and practice.</li>   <br />
              <li>The nature of nursing is such that a substantial portion of the student's learning is acquired in the clinical area with the provision of appropriate clinical facilities and clinical guidance. Periodical evaluation is the responsibility of the staff.</li>   <br />
              <li>Persons drawn to nursing have concern for others' welfare, a belief in personal efficiency and a desire to actively engage in life activities.</li>   <br />
              <li>Professional and non-professional personnel who are organized into self-directed team work can best provide high quality of nursing care.</li>   <br />
              <li>When jobs for nursing personnel are designed, the leader needs to ensure employees autonomy, quality patient care, patient satisfaction, employee satisfaction, and self actualization.</li>   <br />
              <li>Nursing leader has a responsibility in helping the student develop pride in the profession and in guiding her/him with respect to the future in nursing by demonstrating a high standard of professional behavior.</li>   <br />
            </ul>                
        </div>

    );
};


export default Philosphy;