import React from "react";

import './Prindesk.css'


const Prindesk = () => {
    return(
        <div className="container prin-parent mb-5 mt-5">
            <h1 className="text-custom-blue">Principal's Message</h1>
            <h3 className="text-danger mb-4">"Success can not be achieved without continuous efforts"</h3>
            <h1 className="text-custom-blue">Sudha Nair</h1>
            <h3 className="text-danger">Principal</h3>
            <p className="fs-p">Nursing is one of the strongest pillars that the medical profession stands on. It is the noblest of all professions and in immense demand globally offering a highly rewarding career. The profession revolves around taking physical as well as psychological care of the sick and vulnerable group. Education of nurses contributes a lot to promote and maintain the health of the nation as a whole and thereby progress of a country. Transformation of nursing practice for our ever changing and dynamic health system requires a fundamental re-conceptualization of nursing education.
            Hence, Jai Shree Siddhivinayak Foundation’s trust has established B. R. Harne School of Nursing which aims to become the epitome of excellence in rendering quality nursing education and prepare graduates up to the mark of advanced & expert nurses with competent skills in nursing theory as well as nursing practice with emphasis on evidence based nursing practice so as to face global challenges of today and tomorrow. Extend hearty welcome to all the aspirants who are joining this institute and offer my best wishes for their academic journey to usher into the knowledge and to turn out as a successful nursing professional.</p>

        </div>
    );
};

export default Prindesk;