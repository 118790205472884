import React from "react";

const CodeConduct = () => {
    return(
        <div className="container coc-parent mt-4 mb-5">
            <h1 className="text-custom-blue">Code of Conduct</h1>
            <h2 className="text-custom-blue mb-3 mt-4">School Rules and Regulations</h2>

            <ul>
              <li>Discipline of the institute is to be strictly maintained. For breach of discipline, a student may be fined.</li> <br/>
              <li>Leave applications have to be approved and signed by the respective class teachers & Principal before taking leave.</li> <br/>
              <li>Absenteeism has to be informed to the class teacher & Principal before 10am.</li> <br/>
              <li>90% attendance is essential in classes and 100% in practical areas.</li> <br/>
              <li>Students will not be allowed to meet/entertain their relatives during school hours, except in emergencies.</li> <br/>
              <li>Students have to keep their phones on silent mode in the classroom and in clinicals.</li> <br/>
              <li>Students should maintain discipline and silence in the classes, corridors, and library.</li> <br/>
              <li>Students should make every effort to take care of college and hospital property and help in maintaining the same.</li> <br/>
              <li>Students must carry their ID cards with uniforms in the college premises as well as in clinicals.</li> <br/>
              <li>Students have to be punctual and regular in attending theory and practical classes.</li> <br/>
              <li>The regular college timings are 9:30am to 3:45pm.</li> <br/>
              <li>Absence during examinations is not permitted for any emergencies.</li> <br/>
              <li>Greetings should be done every time during college premises.</li> <br/>
              <li>Students have to submit their study assignments within the stipulated time to the concerned teachers.</li> <br/>
              <li>A student nurse must come in clean and tidy uniform with hair tied up and nails trimmed, with a 3-pin tied dupatta. No high heels are allowed (small bindi and 1 bangle are allowed for married people).</li> <br/>
              <li>No long earrings or finger rings should be worn in the clinical area; only small studs are allowed.</li> <br/>
              <li>A student nurse is not allowed to receive gifts or gratuity of any sort at any time from patients of the hospital.</li> <br/>
            </ul>




            <h2 className="text-custom-blue mb-3 mt-4">Anti Ragging Policy</h2>
            <p>AICTE/UGC Regulations as per the Directives of the Supreme Court Ragging constitutes one or more of any of the following acts as per the AICTE/UGC ACT :</p>

            <p className="fs-p">1. Any conduct by any student or students whether by words spoken or written or by an act which has the effect of teasing, treating or handling with rudeness a fresher or any other student;<br/></p>
            <p className="fs-p">2. Including in rowdy or undisciplined activities by any student or students which causes or is likely to cause annoyance, hardship, physical or psychological harm or to raise fear or apprehension thereof in any fresher or any other student:<br/></p>
            <p className="fs-p">3. Asking any student to do any act which such student will not in the ordinary course do and which has the effect of causing or generating a sense of shame, or torment or embarrassment so as to adversely affect the physique or psyche of such fresher or any other student;<br/></p>
            <p className="fs-p">4. Any act by a senior student that prevents, disrupts or disturbs the regular academic activity of any other student or fresher;<br/></p>
            <p className="fs-p">5. Exploiting the services of a fresher or any other student for completing the academic tasks assigned to an individual or a group of students;<br/></p>
            <p className="fs-p">6. Any act of financial extortion or forceful expenditure burden put on a fresher or any other student by students;<br/></p>
            <p className="fs-p">7. Any act of physical abuse including all variants of it; sexual abuse, homosexual assaults, stripping, forcing obscene /lewd acts, gestures, causing bodily harm or any other danger to health or person;<br/></p>
            <p className="fs-p">8. Any act or abuse by spoken words, emails, posts, public insults which would also include deriving prevented pleasure, vicarious or sadistic thrill from actively or passively participating in the discomfiture to fresher or any other student;<br/></p>
            <p className="fs-p">9. Any act that affects the mental health and self-confidence of a fresher or any other student with or without an intent to derive a sadistic pleasure or showing off power, authority or superiority by a student over any fresher or any other student.<br/></p>


            <h2 className="text-custom-blue mb-3 mt-4">Punishments:</h2>
            <p>Depending upon the nature and gravity of the offence as established, the possible punishments as per AICTE/UGC Section 9 for those found guilty of ragging at the institution level, shall be any one or any combination of the following :-</p>

            <p className="fs-p">1) Suspension from attending classes<br/></p>
            <p className="fs-p">2) Withholding/withdrawing scholarship/fellowship and other benefits.<br/></p>
            <p className="fs-p">3) Debarring from appearing in any test/examination or other evaluation process.<br/></p>
            <p className="fs-p">4) Withholding results<br/></p>
            <p className="fs-p">5) Debarring from representing the institution in any regional, national or international meet, tournament, youth Festival etc.<br/></p>
            <p className="fs-p">6) Suspension/expulsion from the hostel<br/></p>
            <p className="fs-p">7) Cancellation of admission<br/></p>
            <p className="fs-p">8) Rustication from the institution for a period ranging from 1 to 4 semesters<br/></p>
            <p className="fs-p">9) Expulsion from the institution and consequent debarring from admission to any other institution.<br/></p>
            <p className="fs-p">10) Fine ranging between Rs.25,000/- and Rs.1,00,000/-<br/></p>
            <p className="fs-p">11) Collective punishment: when the persons committing or abetting the crime of ragging are not identified, the institution shall resort to collective punishment as a deterrent to ensure community pressure on the potential raggers.<br/></p>

        </div>
    );
};


export default CodeConduct;