// import logo from './logo.svg';
// import './App.css';


import { Routes, Route } from 'react-router-dom';

import Header from  './components/Header';
import MyNavbar from  './components/MyNavbar';
import Carousel from './components/Carousel';
import About from './components/About';

import Footer from './components/Footer';




import PO from './components/PO';
import AimObj from './components/AimObj';
import Philosophy from './components/Philosophy';
import Presidesk from './components/Presidesk';
import Tresdesk from './components/Tresdesk';
import Prindesk from './components/Prindesk';

import CodeConduct from './components/CodeConduct';
import Eligibility from './components/Eligibility';
import CofInstruction from './components/CofInstruction';

import Contact from './components/Contact';













const Layout = ({ children }) => (
  <>
  <Header />
  <MyNavbar />
  <div className="main-content">
    {children}
  </div>
  <Footer />
  </>
);

const App = ()  => {
  return (


      <Layout>

      <Routes>
          <Route path="/" element={
            <>
            <Carousel/>
            <About/>


            
            </>

          } />

          <Route path="/PO" element={<PO/>} />
          <Route path="/Aim & Objectives" element={<AimObj/>} />
          <Route path="/Philosophy" element={<Philosophy/>} />
          <Route path="/President desk" element={<Presidesk/>} />
          <Route path="/Treasurer desk" element={<Tresdesk/>} />
          <Route path="/Principal desk" element={<Prindesk/>} />



          <Route path="/CodeConduct" element={<CodeConduct/>} />
          <Route path="/Eligibility" element={<Eligibility/>} />
          <Route path="/CourseofInstruction" element={<CofInstruction/>} />
          <Route path="/Contact" element={<Contact/>} />














        </Routes>

      </Layout>



  );
};

export default App;
