import React, { useState, useEffect, useRef } from 'react';
import './MyNavbar.css';

const MyNavbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const [dropdownsOpen, setDropdownsOpen] = useState({
    team: false,
    event: false,
    program: false,
  });
  const [isFixed, setIsFixed] = useState(false); // Track if navbar should be fixed

  const menuRef = useRef(null);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  const toggleDropdown = (dropdown) => {
    setDropdownsOpen((prev) => ({
      ...prev,
      [dropdown]: !prev[dropdown],
    }));
  };

  const handleHover = (dropdown, isHovered) => {
    if (!isMobile) {
      setDropdownsOpen((prev) => ({
        ...prev,
        [dropdown]: isHovered,
      }));
    }
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 991);
    
    // Set a fixed position after scrolling down 200px
    const handleScroll = () => {
      const threshold = 200; // Set the threshold in pixels
      setIsFixed(window.pageYOffset > threshold); // Fix navbar after scroll > threshold
    };
  
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };
  
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll); // Add scroll event listener
    document.addEventListener('mousedown', handleClickOutside);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll); // Cleanup scroll listener
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  

  return (
    <div className="top-container">
      <nav
        className={`navbar navbar-expand-lg navbar-dark bg-light ${isFixed ? 'fixed-top' : ''}`}
        ref={menuRef}
      >
        <div className="container-fluid">
          {/* Navbar toggler button */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={toggleMenu}
            aria-controls="navbarNav"
            aria-expanded={isMenuOpen}
            aria-label="Toggle navigation"
          >
            <i className={`fas ${isMenuOpen ? 'fa-times' : 'fa-bars'}`}></i>
          </button>

          {/* Navbar links */}
          <div className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`} id="navbarNav">
            <ul className="navbar-nav">
              <li className="nav-item">
                <a className="nav-link" href="/">Home</a>
              </li>
              {/* About Us Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('about')}
                onMouseEnter={() => handleHover('about', true)}
                onMouseLeave={() => handleHover('about', false)}
              >
                <a className="nav-link dropdown-toggle" id="aboutDropdown">
                  About
                </a>
                {dropdownsOpen.about && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/PO">Program Outcomes</a></li>
                    <li><a className="dropdown-item" href="/Aim & Objectives">Aim & Objective</a></li>
                    <li><a className="dropdown-item" href="/Philosophy">Philosophy</a></li>
                  </ul>
                )}
              </li>
              {/* Team Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('team')}
                onMouseEnter={() => handleHover('team', true)}
                onMouseLeave={() => handleHover('team', false)}
              >
                <a className="nav-link dropdown-toggle" href="#" id="teamDropdown">
                  Administration
                </a>
                {dropdownsOpen.team && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/President desk">From President's Desk</a></li>
                    <li><a className="dropdown-item" href="/Treasurer desk">From Treasurer's Desk</a></li>
                    <li><a className="dropdown-item" href="/Principal desk">From Principal's Desk </a></li>
                  </ul>
                )}
              </li>
              {/* Event Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('event')}
                onMouseEnter={() => handleHover('event', true)}
                onMouseLeave={() => handleHover('event', false)}
              >
                <a className="nav-link dropdown-toggle" id="eventDropdown">
                  Departments
                </a>
                {dropdownsOpen.event && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/CodeConduct">Code of Conduct</a></li>
                  </ul>
                )}
              </li>
              {/* Program Dropdown */}
              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('program')}
                onMouseEnter={() => handleHover('program', true)}
                onMouseLeave={() => handleHover('program', false)}
              >
                <a className="nav-link dropdown-toggle" href="#" id="Dropdown">
                  Eligibility Criteria
                </a>
                {dropdownsOpen.program && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/Eligibility">Eligibility</a></li>
                  </ul>
                )}
              </li>

              <li
                className="nav-item dropdown"
                onClick={() => toggleDropdown('courses')}
                onMouseEnter={() => handleHover('courses', true)}
                onMouseLeave={() => handleHover('courses', false)}
              >
                <a className="nav-link dropdown-toggle" href="#" id="coursesDropdown">
                  Courses
                </a>
                {dropdownsOpen.courses && (
                  <ul className="dropdown-menu show">
                    <li><a className="dropdown-item" href="/CourseofInstruction">Course of Instruction</a></li>
                  </ul>
                )}
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/Contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default MyNavbar;
