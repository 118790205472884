import React from "react";
import './AimObj.css';

// import jsslogo from './assets/brand/jssf.png';
// import vision from './assets/stickers/vision.png';
// import mission from './assets/stickers/mission.png';




// import sposter1 from './assets/posters/brhpost1.jpeg';
// import sposter2 from './assets/posters/brhpost2.jpeg';
// import sposter3 from './assets/posters/brhpost3.jpeg';


// import lposter1 from './assets/posters/Addmition.png';
// import lposter2 from './assets/posters/addopen.jpg';
// import lposter3 from './assets/posters/rangoli.jpeg';


const AimObj = () => {
    return (


        <div className="container AimObj-parent mt-5 mb-5">
            <h2 className="text-custom-blue">Aim:</h2>
            <ul>
              <li>Prepare nurses with a sound educational program in nursing to enable them to function as efficient members of the health team.</li> <br />
              <li>Help Nurses develop an ability to co-operate and co-ordinate with members of the health team in the prevention of disease, promotion of health and rehabilitation of the sick.</li> <br />
              <li>Serve as a base for further professional education and specialization in nursing.</li> <br />
            </ul>

            <h2 className="text-custom-blue">Objectives:</h2>
            <ul>
              <li>Assess the nursing need clients from birth to death.</li> <br />
              <li>Plan and carry out appropriate action to meet nursing needs.</li> <br />
              <li>Apply problem solving techniques in nursing practice.</li> <br />
              <li>Apply knowledge from the humanities, biological and behavioral sciences in functioning as a nurse.</li> <br />
              <li>Demonstrate use of ethical values in their personal and professional life.</li> <br />
              <li>Recognize the need for continuing education for professional development.</li> <br />
              <li>Demonstrate basic skills in teaching patients and giving nursing care to them.</li> <br />
              <li>Demonstrate interest in continued learning for personal and professional advancement.</li> <br />
            </ul>
                
                

        </div>
    );
};

export default AimObj;
