import React from "react";


import './Eligibility.css'



const Eligibility = () => {
    return(
        <div className="container eligibility-parent mb-5 mt-5">
            <h1 className="text-custom-blue mt-4 mb-4">Examination Guidelines</h1>
            <p className="fs-p">Shall have one regular examination followed by supplementary examination in a year.<br/></p>
            <p className="fs-p">If a candidate fails, they can be promoted to the next year.<br/></p>
            <p className="fs-p">A candidate can take any number of attempts with a condition that the maximum period allowed is 6 years. However, all previous papers need to be cleared before appearing in the final examination.<br/></p>
            <p className="fs-p">No institution shall submit student average internal marks more than 75%, i.e., if 40 students are admitted in a course, then the average score of the 40 students shall not exceed 75%. Example of 5 students: A=25, B=20, C=22, D=21, E=24. Average score=89.6%. This will not be accepted by the State Nursing Registration Council.<br/></p>
            <p className="fs-p">Minimum pass marks should be 50% in each of the Theory and Practical papers separately.<br/></p>
            <p className="fs-p">Minimum pass marks shall be 40% for English only.<br/></p>
            <p className="fs-p">Theory and Practical exams for Introduction to Computer are to be conducted as a School exam, and marks are to be sent to the SNRC/Board for inclusion in the mark sheet.<br/></p>
            <p className="fs-p">A candidate has to pass in the theory and practical exam separately in each paper.<br/></p>
            <p className="fs-p">If a candidate fails in either the theory or practical paper, he/she has to re-appear for both the papers (Theory and Practical).<br/></p>
            <p className="fs-p">Maximum number of candidates for practical examination should not exceed 20 per day.<br/></p>
            <p className="fs-p">All practical examinations must be held in the respective clinical areas.<br/></p>
            <p className="fs-p">One internal and one external examiner should jointly conduct the practical examination for each student.<br/></p>



            <h2 className="text-custom-blue mt-4 mb-4">Eligibility for Admission to Examination</h2>
            <p className="fs-p">A candidate shall be eligible for the admission to the State Council/Board examination if the Principal of the school certifies that:<br/></p>
            <p className="fs-p">(a) She/he has completed not less than eleven months of the course.<br/></p>
            <p className="fs-p">(b) A candidate must have a minimum of 80% attendance (irrespective of the kind of absence) in theory and practical in each subject for appearing for examination.<br/></p>

            <p className="fs-p">The diploma shall not be awarded to the student till she/he has completed the clinical/field requirements.<br/></p>
            <p className="fs-p">(c) A candidate must secure 50% marks in internal assessment separately in each theory and practical.<br/></p>
            <p className="fs-p">(d) The record of practical experience is complete.<br/></p>
            <p className="fs-p">(The Principal shall send to the Council/Board the internal assessment for each subject, i.e., both theory and practical(s) before the start of the examination along with the examination form).<br/></p>


            




            <h2 className="text-custom-blue  mt-4 mb-4">Grading of Examination</h2>
            <p className="fs-p">Examination shall be graded on aggregate marks of the entire three years of the training programme, as follows:</p>
            <table class="table table-bordered table-striped">
          <tbody>
            <tr>
              <td>Distinction</td>
              <td>80% and above</td>
            </tr>
            <tr>
              <td>First Division</td>
              <td>70% to 79%</td>
            </tr>
            <tr>
              <td>Second Division</td>
              <td>60% to 69%</td>
            </tr>
            <tr>
              <td>Pass</td>
              <td>50% to 59%</td>
            </tr>
          </tbody>
        </table>


        <h2 className="text-custom-blue mb-4">Theory of Examination</h2>
  <p className="fs-p">
    1. Nursing teacher with a minimum of five years of teaching experience (recent) in a particular subject may be appointed as paper setters and examiners for that particular subject only.
  </p>
  <p className="fs-p">
    2. Question paper should have a combination of essay, short answer, and objective type questions as detailed in the content.
  </p>
  <p className="fs-p">
    3. All units of a subject and sub-subject should be given due weightage in accordance with the instructional hours prescribed.
  </p>

  <h2 className="text-custom-blue mb-4">Practical Examination</h2>
  <p className="fs-p">
    1. Practical examination is to be conducted in the respective clinical area.
  </p>
  <p className="fs-p">
    2. Nursing teacher with a minimum of five years of teaching/clinical teaching experience in a particular subject/clinical area may be appointed as a practical examiner.
  </p>


        </div>
    );
};


export default Eligibility;