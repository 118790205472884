import React from "react";

import './Tresdesk.css';

import tresimg from './assets/brand/treasurer.png'


const Tresdesk = () => {
    return (
        <div className="container tres-parent mt-5 mb-5">
            <h1 className="text-custom-blue">From Treasurer's Desk</h1>
            <h3 className="text-danger">"Courage and simplicity is the power to win"</h3>


            <div className="row mt-5">
                <div className="col-12 col-md-6 pres-col">
                    <img src={tresimg} className="img-fluid tres-img"  alt="" />
                </div>
                <div className="col-12 col-md-6 ">
                    <h2 className="text-custom-blue">Mr. Rushikesh M. Harne</h2>
                    <h3 className="text-danger">Treasurer</h3>
                    <h4>Jai Shree Siddhivinayak Foundation Dombivali (West)</h4>
                    <p className="fs-p">
                    Having been haunted by the socioeconomic & ethical transformation of our country, the foundation stone of the educational complex of B.R. Harne Educational Campus under the canopy of Jai Shri Siddhivinayak Foundation was laid in the year 2007. It is growing year by year.
The mission of establishing the educational campus is to impart quality education by including innovative courses & creating a learner-friendly environment. I believe that the students completing various courses will not only have ample job opportunities in India and abroad but can also look forward to becoming entrepreneurs & researchers.
I extend a hearty & warm welcome to you at B. R. Harne School Of Nursing, which will definitely shape your mind & character.

                    </p>

                </div>
            </div>
            
        </div>
    );
};


export default Tresdesk;