import React from "react";



const CofInstruction = () => {
    return(
        <div className="container coi-parent mb-5 mt-4">
                    <h2 className="text-custom-blue mb-4 mt-4">First Year</h2>
                    <table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Subjects</th>
      <th>Theory (hours)</th>
      <th>Practical (hours)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Bio Sciences <br/> -Anatomy & Physiology <br/> -Microbiology</td>
      <td>120 <br/> 90 <br/>30</td>
      <td>-</td>
    </tr>
    <tr>
      <td>Behavioral Sciences <br/> -Psychology <br/> -Sociology</td>
      <td>60 <br/>40 <br/>20</td>
      <td>-</td>
    </tr>
    <tr>
      <td>Nursing Foundations <br/>-Fundamentals of Nursing  <br/>-First Aid</td>
      <td>210 <br/>190 <br/>20</td>
      <td>200 (lab) | 680 (clinic) (22 weeks)</td>
    </tr>
    <tr>
      <td>Community Health Nursing</td>
      <td>180</td>
      <td>-</td>
    </tr>
    <tr>
      <td>CHN-1</td>
      <td>80</td>
      <td>-</td>
    </tr>
    <tr>
      <td>Environmental Hygiene</td>
      <td>30</td>
      <td>320 (8 weeks)</td>
    </tr>
    <tr>
      <td>Health Education & Communication Skills</td>
      <td>40</td>
      <td>-</td>
    </tr>
    <tr>
      <td>Nutrition</td>
      <td>30</td>
      <td>-</td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Subjects</th>
      <th>Theory (hours)</th>
      <th>Practical (hours)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>English</td>
      <td>30</td>
      <td>-</td>
    </tr>
    <tr>
      <td>Computer Education</td>
      <td>15</td>
      <td>15</td>
    </tr>
    <tr>
      <td>Co-curricular activities</td>
      <td>10</td>
      <td>-</td>
    </tr>
    <tr>
      <td>Total</td>
      <td>625 (16 weeks)</td>
      <td>1215 (30 weeks)</td>
    </tr>
  </tbody>
</table>



<h2 className="text-custom-blue mb-4 mt-4">Second Year</h2>

<table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Subjects</th>
      <th>Theory (hours)</th>
      <th>Practical (hours)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Medical Surgical Nursing - I</td>
      <td>120</td>
      <td>800 (20 weeks)</td>
    </tr>
    <tr>
      <td>Medical Surgical Nursing - II</td>
      <td>120</td>
      <td>800 (20 weeks)</td>
    </tr>
    <tr>
      <td>Mental Health Nursing</td>
      <td>70</td>
      <td>320 (8 weeks)</td>
    </tr>
    <tr>
      <td>Child Health Nursing</td>
      <td>70</td>
      <td>320 (8 weeks)</td>
    </tr>
    <tr>
      <td>Co-curricular activities</td>
      <td>20</td>
      <td>-</td>
    </tr>
    <tr>
      <td>Total</td>
      <td>400 (10 weeks)</td>
      <td>1440 (36 weeks)</td>
    </tr>
  </tbody>
</table>



<h2 className="text-custom-blue mb-4 mt-4">Third Year Part-1</h2>


<table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Subjects</th>
      <th>Theory (hours)</th>
      <th>Practical (hours)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Midwifery & Gynecological Nursing</td>
      <td>140</td>
      <td>560 (14 weeks)</td>
    </tr>
    <tr>
      <td>Community Health Nursing – II</td>
      <td>90</td>
      <td>160 (4 weeks)</td>
    </tr>
    <tr>
      <td>Co-curricular</td>
      <td>10</td>
      <td>-</td>
    </tr>
    <tr>
      <td>Total</td>
      <td>240 hours (6 weeks)</td>
      <td>720 hours (18 weeks)</td>
    </tr>
    <tr>
      <td></td>
      <td>960</td>
      <td></td>
    </tr>
  </tbody>
</table>


<h2 className="text-custom-blue mb-4 mt-4">Third Year Part – II (Integrated supervised Internship)</h2>


<table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Subjects</th>
      <th>Theory (hours)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Nursing Education</td>
      <td>20</td>
    </tr>
    <tr>
      <td>Introduction to Research & Statistics</td>
      <td>30</td>
    </tr>
    <tr>
      <td>Professional Trends & Adjustments</td>
      <td>30</td>
    </tr>
    <tr>
      <td>Nursing Administration & Ward Management</td>
      <td>40</td>
    </tr>
    <tr>
      <td>Total</td>
      <td>120 hours (2 weeks)</td>
    </tr>
  </tbody>
</table>

<table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Paper</th>
      <th>Subjects</th>
      <th>Total Marks</th>
      <th>Internal Assessment</th>
      <th>Council/ Board Examination</th>
      <th>Weightage</th>
      <th>Duration of Examination (Hrs.)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1.</td>
      <td>Bioscience <br/> -Anatomy & Physiology <br/> -Microbiology</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td>60% <br/> 35% </td>
      <td>3</td>
    </tr>
    <tr>
      <td>2.</td>
      <td>Behavioral Science <br/> -Psychology <br/> -Sociology</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td>60% <br/> 35%</td>
      <td>3</td>
    </tr>
    <tr>
      <td>3.</td>
      <td>Foundation of Nursing <br/> -Fundamentals of Nursing <br/> -First-aid</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td></td>
      <td></td>
    </tr>
    <tr>
      <td>4.</td>
      <td>Community Health Nursing <br/> -CHN-1 <br/> -Environmental Hygiene  <br/>-Health Education and Communication Skills <br/> -Nutrition</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td>50% <br/> 10%  <br/>25%<br/> 15% </td>
      <td>3</td>
    </tr>
    <tr>
      <td></td>
      <td>Practical I <br/>Fundamentals of Nursing</td>
      <td>100</td>
      <td>50</td>
      <td>50</td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>



<h2 className="text-custom-blue mb-4 mt-4">Scheme of Examination</h2>
<h2 className="text-custom-blue mb-4 mt-4">First Year</h2>


<table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Paper</th>
      <th>Subjects</th>
      <th>Total Marks</th>
      <th>Internal Assessment</th>
      <th>Council/ Board Examination</th>
      <th>Weightage</th>
      <th>Duration of Examination (Hrs.)</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>1</td>
      <td>Medical Surgical Nursing I</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td></td>
      <td>3</td>
    </tr>
    <tr>
      <td>2</td>
      <td>Medical Surgical Nursing II</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td></td>
      <td>3</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Mental Health Nursing</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td></td>
      <td>3</td>
    </tr>
    <tr>
      <td>4</td>
      <td>Child Health Nursing</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td></td>
      <td>3</td>
    </tr>
    <tr>
      <td></td>
      <td>Practical I <br/>Medical Surgical Nursing</td>
      <td>100</td>
      <td>50</td>
      <td>50</td>
      <td></td>
      <td>-</td>
    </tr>
    <tr>
      <td></td>
      <td>Practical II <br/>Child Health Nursing</td>
      <td>100</td>
      <td>50</td>
      <td>50</td>
      <td></td>
      <td>-</td>
    </tr>
    <tr>
      <td></td>
      <td>Practical III <br/>Mental Health Nursing</td>
      <td>100</td>
      <td>50</td>
      <td>50*</td>
      <td></td>
      <td>-</td>
    </tr>
  </tbody>
</table>

<h2 className="text-custom-blue mb-4 mt-4">Third Year Part-1</h2>


<table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Paper</th>
      <th>Subjects</th>
      <th>Total Marks</th>
      <th>Internal Assessment</th>
      <th>Council/ Board Examination</th>
      <th>Weightage</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>I</td>
      <td>Midwifery & Gynecological Nursing</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td>3</td>
    </tr>
    <tr>
      <td>II</td>
      <td>Community Health Nursing – II</td>
      <td>100</td>
      <td>25</td>
      <td>75</td>
      <td>3</td>
    </tr>
    <tr>
      <td></td>
      <td>Practical I</td>
      <td>100</td>
      <td>50</td>
      <td>50</td>
      <td>3</td>
    </tr>
    <tr>
      <td>3</td>
      <td>Practical II</td>
      <td>100</td>
      <td>50</td>
      <td>50</td>
      <td>3</td>
    </tr>
  </tbody>
</table>


<h2 className="text-custom-blue mb-4 mt-4">Third Year Part-II (School Examination)</h2>

<table class="table table-bordered table-striped">
  <thead class="table-dark">
    <tr>
      <th>Paper</th>
      <th>Subjects</th>
      <th>Total Marks</th>
      <th>Internal Assessment</th>
      <th>Council/ Board Examination</th>
      <th>Weightage</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>I</td>
      <td>Nursing Education & Introduction to Research & Statistics</td>
      <td>100</td>
      <td>50</td>
      <td>50</td>
      <td>3</td>
    </tr>
    <tr>
      <td>II</td>
      <td>Professional Trends & Adjustment, Nursing administration & ward Management</td>
      <td>100</td>
      <td>50</td>
      <td>50</td>
      <td>3</td>
    </tr>
  </tbody>
</table>




        </div>

    );
};



export default CofInstruction;