import React from "react";



const PO = () => {
    return(
        <div className="container po-parent mb-5 ">
            <h2 className="text-custom-blue mt-5">General Nursing Midwifery (G.N.M)</h2>
            <p>Program Outcomes To impart quality nursing education and prepare competent, caring and compassionate nursing professionals who will adhere to the code of ethics and professional conduct while fulfilling their personal, social and professional obligations in the course of their practice Program Specific Outcomes On completion of G.N.M Nursing degree programmed the graduates will be able to:</p>

            <ul>
              <li>Demonstrate competency in the skills and techniques of nursing based on concepts and principles.</li> <br />
              <li>Assess the health status, identify and prioritize the nursing needs, plan, implement and evaluate care of the patients in the hospital and community.</li>  <br />
              <li>Assume leadership roles as first level managers in nursing services and nursing education.</li>  <br />
              <li>Recognize the need for continued learning for personal and professional enhancement.</li>  <br />
              <li>Update oneself with the latest technology, trends and issues in health care.</li>  <br />
              <li>Conduct need based research studies in the hospital / community and utilize the research findings to improve the quality of nursing care.</li>  <br />
              <li>Practice within the framework of the code of ethics and professional conduct and ensure acceptable standards of practice.</li>  <br />
              <li>Collaborate with the other members of the health care team in reaching realistic health goals.</li>  <br />
              <li>Communicate effectively with the members of the health care team and develop good interpersonal skills for team work.</li>  <br />
            </ul>

            <h2 className="text-custom-blue">Program Outcome</h2>
            <p>To upgrade the diploma nurses to assume responsibility as professional, competent nurses and midwives at basic level in providing promotive, preventive, curative and rehabilitative services. To enable them to make independent decisions in nursing situations, protect the rights of and facilitate individuals and groups in pursuit of health, function in the hospital, community nursing services and conduct research studies in the areas of nursing practice. They are also expected to assume the role of teacher, supervisor, manager in the clinical/public health setting.</p>


            <h2 className="text-custom-blue">Program Specific Outcome</h2>
            <p>On the completion of post basic GNM degree program the graduates will be able to:</p>
            <p>1. Assess health status, identify nursing needs, plan, implement and evaluate nursing care for patients that contribute to health of individuals, families and communities.</p>
            <p>2. Demonstrate competency in techniques of nursing based on concepts and principles from selected areas of nursing, physical, biological and behavioral sciences.</p>
            <p>3. Participate as members of health team in the promotive, preventive, curative and restorative health care delivery system of the country.</p>
            <p>4. Demonstrate skills in communication and interpersonal relationship.</p>
            <p>5. Demonstrate leadership qualities and decision making abilities in various situations.</p>
            <p>6. Demonstrate skills in teaching to individuals and groups in community health settings.</p>
            <p>7. Demonstrate managerial skills in community health settings.</p>
            <p>8. Practice ethical values in their personal and professional life.</p>
            <p>9. Participate in research activities and utilize research findings in improving nursing practice.</p>


        </div>
    );
};



export default PO;